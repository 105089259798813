export default {

    request: function (req, token) {
        req.headers['Authorization'] = 'Bearer ' + token;
    },
    response: function (res) {
        if (res.data.token)
            return res.data.token

    }
};