export default {
    "data": [
        {
            "key": "home",
            "path": ":slug",
            "name": "Home",
            "is_menu_item" : true
        },
        {
            "key": "about",
            "path": "about/:slug",
            "name": "About",
            "is_menu_item" : true
        },
        {
            key : "entry-details",
            path : "entry-details/:slug",
            name : "EntryDetails",
            is_menu_item : false
        },
        {
            "key": "documents",
            "path": "documents/:slug",
            "name": "Procedure",
            "is_menu_item" : true
        },
        {
            "key": "evaluation-criteria",
            "path": "criteria/:slug",
            "name": "EvaluationCriteria",
            "is_menu_item" : true
        },
        {
            "key": "register",
            "path": "register/:slug",
            "name": "Register",
            "is_menu_item" : true
        },
        {
            "key": "application",
            "path": "application",
            "name": "Application",
            "is_menu_item" : true
        },
        {
            "key": "faq",
            "path": "faq/:slug",
            "name": "FAQ",
            "is_menu_item" : false
        },
        {
            "key": "login",
            "path": "login",
            "name": "Login",
            "is_menu_item" : true
        },
        {
            "key": "application",
            "path": "application/:slug/:identifier",
            "name": "Application",
            "is_menu_item" : false
        },
        {
            "key": "call",
            "path": "call/:slug",
            "name": "Call",
            "is_menu_item" : false
        }
    ]
}