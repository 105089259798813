<template>
  <header id="header" class="fixed-top">
    <div class="container d-flex align-items-center">

      <h1 class="logo me-auto"><router-link :to="{name : 'Home'}">{{$t('sira')}}</router-link></h1>
      <!-- Uncomment below if you prefer to use an image logo -->
      <!-- <a href="index.html" class="logo me-auto"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->

      <nav id="navbar" class="navbar order-last order-lg-0">
        <ul>
          <!-- <li v-for="(menu, m) in menus" :key="`menu-${m}`">
            <router-link v-if="menu.is_menu_item" :class="`${$route.name === menu.name ? 'active' : ''}`" :to="{name : menu.name}">
              <div v-if="loggedIn && (menu.name === 'Register' || menu.name === 'Login')">{{$t(menu.key)}}</div>
              <div v-else>{{$t(menu.key)}}</div>
            </router-link>
          </li> -->
          <li><router-link :class="`${$route.name == 'Home' ? 'active' : ''}`" :to="{name : 'Home'}">{{$t("home")}}</router-link></li>
          <li><router-link :class="`${$route.name == 'About' ? 'active' : ''}`" :to="{name : 'About'}">{{$t("about")}}</router-link></li>
          <li><router-link :class="`${$route.name == 'Documents' ? 'active' : ''}`" :to="{name : 'Documents'}">{{$t("documents")}}</router-link></li>
          <li><router-link :class="`${$route.name == 'ImportantDates' ? 'active' : ''}`" :to="{name : 'ImportantDates'}">{{$t("important-dates")}}</router-link></li>
          <li><router-link :class="`${$route.name == 'Winners' ? 'active' : ''}`" :to="{name : 'Winners'}">{{$t("winners")}}</router-link></li>
          <li><router-link :class="`${$route.name == 'Application' ? 'active' : ''}`" :to="{name : 'Application'}">{{$t("application")}}</router-link></li>
          <li><router-link v-if="!$auth.check()" :class="`${$route.name == 'Register' ? 'active' : ''}`" :to="{name : 'Register'}">{{$t("register")}}</router-link></li>
          <li><router-link v-if="!$auth.check()" :class="`${$route.name == 'Login' ? 'active' : ''}`" :to="{name : 'Login'}">{{$t("login")}}</router-link></li>
          <li><router-link :class="`${$route.name == 'Contact' ? 'active' : ''}`" :to="{name : 'Contact'}">{{$t("contact-us")}}</router-link></li>
          <!-- <li><a href="#" v-if="loggedIn" @click.prevent="logout" class="btn">{{$t("logout")}}</a></li> -->

          <!-- <li class="dropdown"><a href="#"><span>Drop Down</span> <i class="bi bi-chevron-down"></i></a>
            <ul>
              <li><a href="#">Drop Down 1</a></li>
              <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
                <ul>
                  <li><a href="#">Deep Drop Down 1</a></li>
                  <li><a href="#">Deep Drop Down 2</a></li>
                  <li><a href="#">Deep Drop Down 3</a></li>
                  <li><a href="#">Deep Drop Down 4</a></li>
                  <li><a href="#">Deep Drop Down 5</a></li>
                </ul>
              </li>
              <li><a href="#">Drop Down 2</a></li>
              <li><a href="#">Drop Down 3</a></li>
              <li><a href="#">Drop Down 4</a></li>
            </ul>
          </li> -->
          <!-- <li><a href="contact.html">Contact</a></li> -->
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav><!-- .navbar -->
      <a href="#" v-if="$auth.check()" @click.prevent="logout" class="get-started-btn blue">{{$t("logout")}}</a>

      <a href="#" @click.prevent="switchLanguage()" class="get-started-btn">{{current_language}}</a>

    </div>
  </header>
</template>

<script>
import menus from '../data/menu.js'
export default {
  data(){
    return {
      menus : menus.data,
      current_locale : this.$i18n.locale === "en" ? "fr" : "en", 
      current_language : this.$i18n.locale === "en" ? this.$t("view-french") : this.$t("view-english"),
    }
  },
  methods : {
    switchLanguage(){
      this.$i18n.locale = this.current_locale;
      localStorage.setItem("locale", this.current_locale);
      this.current_locale = this.$i18n.locale === "en" ? "fr" : "en";
      this.current_language = this.$i18n.locale == "en" ? this.$t("view-french") : this.$t("view-english")
    },
    logout(){
      this.$auth.logout();
    }
  }
}
</script>

<style>
.blue {
  background-color: blue;
}
</style>
