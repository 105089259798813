import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import VueMeta from 'vue-meta';
import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css' //Vuesax styles
import VueHead from "vue-head"
import http from "./http"
import config from "./config"

import VueCompositionAPI from "@vue/composition-api"



Vue.use(Vuesax);
Vue.use(VueHead);

Vue.config.productionTip = false
Vue.use(VueMeta);

// Composition API
Vue.use(VueCompositionAPI)

// Vue.use(VueAuth, auth)

// use beforeEach route guard to set the language
router.beforeEach((to, from, next) => {
  let locales = ["en", "fr"]

  let language = localStorage.getItem("locale");
  if (!language || !locales.includes(language)) {
    language = 'en';
    next();
  }

  // set the current language for i18n.
  i18n.locale = language
  next()
})

function loggedIn(){
  return localStorage.getItem("user-token")
}


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!loggedIn()) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      })
      // router.go();
    } else {
      next()
    }
  } else if(to.matched.some(record => record.meta.guest)){
      if (loggedIn()) {
          next({
            path: `/`,
            query: { redirect: to.fullPath }
          })
          // router.go();
        } else {
          next()
        }
  } else {
      next() // make sure to always call next()!
  }
})


new Vue({
  http: http,
  store: store,
  router: router,
  config: config,
  i18n : i18n,
  render: h => h(App),
  data(){
    return {
      direction : i18n.locale ==="ar"?"rtl": "ltr"
    }
  },
  created(){
    let htmlEl=document.querySelector("html");
     htmlEl.setAttribute('dir',this.direction);
     htmlEl.setAttribute('lang', i18n.locale);
   }
}).$mount('#app')
