export default [
    {
        path: "/",
        name: "Home",
        component: () => import("@/views/Home.vue"),
        meta : {
            auth : undefined
        }
    },
    {
        path: "/about",
        name: "About",
        component: () => import("@/views/About.vue"),
        meta : {
            auth : undefined
        }
    },
    {
        path : "entry-details",
        name : "EntryDetails",
        component: () => import("@/views/EntryDetails.vue"),
        meta : {
            auth : undefined
        }
    },
    {
        path: "/documents",
        name: "Documents",
        component: () => import("@/views/Documents.vue"),
        meta : {
            auth : undefined
        }
    },
    {
        path: "/important-dates",
        name: "ImportantDates",
        component: () => import("@/views/ImportantDates.vue"),
        meta : {
            auth : undefined
        }
    },
    {
        path: "/register",
        name: "Register",
        component: () => import("@/views/Register.vue"),
        meta : {
            auth : false
        }
    },
    {
        path: "/confirm-email-address",
        name: "ConfirmEmailAddress",
        component: () => import("@/views/ConfirmEmailAddress.vue"),
        meta : {
            auth : undefined
        }
    },
    {
        path: "/password-reset",
        name: "PasswordReset",
        component: () => import("@/views/PasswordReset.vue"),
        meta : {
            auth : undefined
        }
    },
    {
        path: "/winners",
        name: "Winners",
        component: () => import("@/views/Winners.vue"),
        meta : {
            auth : undefined
        }
    },
    // {
    //     path: "/form-submission",
    //     name: "FormSubmission",
    //     component: () => import("@/views/Application.vue"),
    // },
    {
        path: "/contact",
        name: "Contact",
        component: () => import("@/views/Contact.vue"),
        meta : {
            auth : undefined
        }
    },
    {
        path: "/application",
        name: "Application",
        component: () => import("@/views/Application.vue"),
        meta: {
            auth : true
        },
    },
    {
        path: "/consent",
        name: "Consent",
        component: () => import("@/views/Consent.vue"),
        meta: {
            auth : true
        },
    },
    {
        path: "/review",
        name: "Review",
        component: () => import("@/views/Review.vue"),
        meta: {
            auth : true
        },
    },
    {
        path: "/call",
        name: "Call",
        component: () => import("@/views/Call.vue"),
        meta : {
            auth : undefined
        }
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/Login.vue"),
        meta : {
            auth : false
        }
    }
]