import Vue from 'vue'
import VueRouter from 'vue-router'
import pages from "./pages.routes"
// import menus from "../data/menu"
// import i18n from "../i18n";

Vue.use(VueRouter)

const routes = [
//   {
//     path: "/",
//     name: "Home",
//     component: () => import("@/views/Home.vue"),
// },
  ...pages
]

Vue.router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default Vue.router
