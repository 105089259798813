import Vue from 'vue'

import auth from '@websanova/vue-auth/src/v2.js';
// import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer.js';
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x.js';
// import driverHttpVueResource from '@websanova/vue-auth/src/drivers/http/vue-resource.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x.js';
import driverOAuth2Google from '@websanova/vue-auth/src/drivers/oauth2/google.js';
import driverOAuth2Facebook from '@websanova/vue-auth/src/drivers/oauth2/facebook.js';
import customBearer from "../utils/@websanova-vue-auth-bearer"
// import router from '../router'

driverOAuth2Google.params.client_id = '547886745924-4vrbhl09fr3t771drtupacct6f788566.apps.googleusercontent.com';
driverOAuth2Facebook.params.client_id = '196729390739201';

Vue.use(auth, {
    plugins: {
        http: Vue.axios, // Axios
        // http: Vue.http, // Vue Resource
        router: Vue.router,
    },
    drivers: {
        auth: customBearer,
        http: driverHttpAxios,
        router: driverRouterVueRouter,
        oauth2: {
            google: driverOAuth2Google,
            facebook: driverOAuth2Facebook,
        }
    },
    options: {
        rolesKey: 'type',
        rememberKey: 'auth_remember',
        staySignedInKey: 'auth_stay_signed_in',
        tokenDefaultKey: 'auth_token_default',
        tokenImpersonateKey: 'auth_token_impersonate',
        stores: ['storage', 'cookie'],
        notFoundRedirect: { name: 'Application' },
        loginData: {
            url: "/login",
            method: 'POST',
            redirect: '/application',
            staySignedIn: true,
            fetchUser: true
        },
        registerData: {
            url: '/applicants',
            method: 'POST',
            redirect: '/login',
            autoLogin: false
        },
        fetchData: {
            url: "/me",
            method: 'GET',
            enabled: true
        },
        logoutData: {
            url: '/logout',
            method: 'POST',
            redirect: '/login',
            makeRequest: true
        },
        refreshData: {
            url: '/users/refresh-token',
            method: 'GET',
            enabled: true,
            interval: 120
        },

    }
});