<template>
   <footer id="footer">

    <div class="footer-top">
      <div class="container">
        <div class="row">

          <div class="col-lg-4 col-md-6 footer-contact">
            <h3>{{$t('sira')}}</h3>
            <p v-html="$t('address-footer')">
              
            </p>
          </div>

          <div class="col-lg-2 col-md-6 footer-links">
            <h4>{{$t("useful-links")}}</h4>
            <ul>
              <li><i class="bx bx-chevron-right"></i> <router-link :to="{name : 'Home'}">{{$t('home')}}</router-link></li>
              <li><i class="bx bx-chevron-right"></i> <a href="https://ace.aau.org">{{$t("ace-impact")}}</a></li>
              <!-- <li><i class="bx bx-chevron-right"></i> <a href="#">Services</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Terms of service</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">Privacy policy</a></li> -->
            </ul>
          </div>

          <div class="col-lg-4 col-md-6 footer-links">
            <h4>{{$t("other-relevant-links")}}</h4>
            <ul>
            <li><i class="bx bx-chevron-right"></i> <a href="https://aau.org">{{$t("aau")}}</a></li> 
              <!-- <li><i class="bx bx-chevron-right"></i> <a href="#">Submit an article</a></li>
              <li><i class="bx bx-chevron-right"></i> <a href="#">News and Announcement</a></li> -->
              <li><i class="bx bx-chevron-right"></i> <a href="#">{{$t("ace-impact-centres")}}</a></li>
              <!-- <li><i class="bx bx-chevron-right"></i> <a href="#">{{$t("project-documents")}}</a></li> -->
            </ul>
          </div>

          <!-- <div class="col-lg-4 col-md-6 footer-newsletter">
            <h4>Join Our Newsletter</h4>
            <p>Some introduction text here</p>
            <form action="" method="post">
              <input type="email" name="email"><input type="submit" value="Subscribe">
            </form>
          </div> -->

        </div>
      </div>
    </div>

    <div class="container d-md-flex py-4">

      <div class="me-md-auto text-center text-md-start">
        <div class="copyright">
          &copy; {{$t("copyright")}} <strong><span>{{$t("ace-impact")}}</span></strong>. {{$t("all-rights-reserved")}}
        </div>
        <div class="credits">
          <!-- All the links in the footer should remain intact. -->
          <!-- You can delete the links only if you purchased the pro version. -->
          <!-- Licensing information: https://bootstrapmade.com/license/ -->
          <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/mentor-free-education-bootstrap-theme/ -->
          <!-- Designed by <a href="https://bootstrapmade.com/">BootstrapMade</a> -->
        </div>
      </div>
      <div class="social-links text-center text-md-right pt-3 pt-md-0">
        <a href="https://twitter.com/the_ACEProject" class="twitter"><i class="bx bxl-twitter"></i></a>
        <a href="https://web.facebook.com/AfricaHigherEducationCentersofExcellence" class="facebook"><i class="bx bxl-facebook"></i></a>
        <!-- <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a> -->
        <!-- <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a> -->
        <!-- <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a> -->
      </div>
    </div>
  </footer>
</template>

<script>
export default {

}
</script>

<style>

</style>