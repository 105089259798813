<template>
  <div id="app">
    <main id="main" class="card">
      <Header />
      <transition name="fade" mode="out-in">
        <router-view :key="$route.path"></router-view>
        <!-- <router-view/> -->
      </transition>
      <!-- <vue-progress-bar></vue-progress-bar> -->
      <Footer />
       <div id="preloader"></div>
      <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
    </main>
    
  </div>
</template>

<script>
import Footer from "./components/Footer.vue";
import Header from "./components/Header.vue";
export default {
  components: { Header, Footer },
  metaInfo() {
    return {
      title: this.$t("sira-full"),
      // htmlAttrs: {
      //   lang: this.$i18n.locale,
      //   amp: undefined
      // },
      meta: [
        { name: "description", content: "" },
        { property: "og:title", content: "" },
        { property: "og:site_name", content: "" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "index,follow" },
      ],
    };
  },
  head : {
    // title: {
    //   inner: 'It will be a pleasure'
    // },
    script: [
      { type: 'text/javascript', src: '/assets/vendor/purecounter/purecounter.js',  body: true}, // Insert in body
      { type: 'text/javascript', src: '/assets/vendor/aos/aos.js',  body: true},
      { type: 'text/javascript', src: '/assets/vendor/bootstrap/js/bootstrap.bundle.min.js',  body: true},
      // { type: 'text/javascript', src: '/assets/vendor/swiper/swiper-bundle.min.js', async:true,  body: true},
      { type: 'text/javascript', src: '/assets/vendor/php-email-form/validate.js',  body: true},
      { type: 'text/javascript', src: '/assets/js/main.js',  body: true},
    ]
  },
  mounted(){
    // window.location.href = `https://sira.ace.aau.org${this.$route.fullPath}`;
    // window.location.href = this.$route.query.redirect ?? "/application";
    // this.$http.post("/refresh")
    // .then(response => {
    //   localStorage.removeItem("user-token");
    //   localStorage.setItem("user-token", response.data.access_token);
    //   localStorage.setItem("applicant_id", response.data.user.id);
    //   localStorage.setItem("applicant_name", response.data.user.name)
    // }).catch(e => {
    //   // localStorage.removeItem("user-token");
    //   // localStorage.removeItem("applicant_id");
    //   // localStorage.removeItem("applicant_name")
    //   console.log(e);
    // })
    // localStorage.removeItem("user-token");
    
  }
};
</script>

<style>
</style>

